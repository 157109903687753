.App {
  font-family: Arial, sans-serif;
  text-align: center;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.chat-window {
  border: 1px solid #ddd;
  width: 80%;
  height: 300px;
  margin: 0 auto;
  overflow-y: auto;
  padding: 10px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
  resize: vertical; /* Позволяет изменять размер по вертикали */
}

.message-block {
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  text-align: left;
  animation: fadeIn 0.5s ease; /* Анимация появления */
}

.message-user {
  font-weight: bold;
  margin-bottom: 5px;
}

.message-text {
  font-size: 14px;
}

.message-timestamp {
  font-size: 12px;
  color: gray;
  text-align: right;
  margin-top: 5px;
}

.input-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.username-input {
  width: 10%;
  padding: 10px;
  margin-right: 10px;
}

.message-input {
  width: 50%;
  padding: 10px;
  margin-right: 10px;
}

button {
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

button:hover {
  background-color: #0056b3;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
